// body variable
let body = document.querySelector('body');
// navigation variables 
let dropdownButtonFigures = document.querySelectorAll('.mobile-nav-icons');
let dropdownButtons = document.querySelectorAll('.mobile-nav-menu-icon');
let dropdown = document.querySelector('.dropdown');
// dropdown list item variables
let dropdownListItems = document.querySelectorAll('.dropdownListItem')
// header variable
let header = document.querySelector('header');
// switches the menu and close icons when clicked
function switchNavIcons() {
    for (i = 0; i < dropdownButtonFigures.length; i++) {
        dropdownButtonFigures[i].classList.toggle('hide');

    }
}
// gets rid of the background gradient
function toggleopaqueHeader() {
    header.classList.toggle('opaqueHeader');
}
// event listener for the menu icons. improved with toggles. 
for (k = 0; k < dropdownButtons.length; k++) {
        dropdownButtons[k].addEventListener('click', (e) => {
            // toggles the menu
            dropdown.classList.toggle('hide');
            toggleopaqueHeader();
            switchNavIcons();
        })
    }
    // event listener for the menu list items. 
    for (k = 0; k < dropdownListItems.length; k++) {
        dropdownListItems[k].addEventListener('click', (e) => {
                dropdown.classList.toggle('hide');
                toggleopaqueHeader();
                switchNavIcons();
            })
        } 